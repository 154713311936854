/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useSelect } from 'downshift';
import { Label } from '../label/label';

export function Select({
  defaultSelectedItem,
  disabled,
  errorMessage,
  invalid,
  items = [],
  label,
  onChange,
  value,
}) {
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    itemToString: (item) => (item ? item.label : ''),
    items,
    selectedItem: value,
    defaultSelectedItem,
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem);
    },
  });
  const theme = useTheme();

  return (
    <div
      css={css`
        margin-bottom: 10px;
        position: relative;

        @media ${theme.breakpoints.desktop} {
          margin-bottom: 17px;
        }
      `}
    >
      <Label {...getLabelProps()}>{label}</Label>
      <button
        css={css`
          align-items: center;
          appearance: none;
          background-color: ${theme.colors.white};
          border: 1px solid
            ${invalid ? theme.colors.danger : theme.colors['gray-dark']};
          border-radius: 0;
          cursor: pointer;
          display: flex;
          height: 37px;
          font-size: 15px;
          line-height: normal;
          padding: 0 10px;
          text-align: left;
          width: 100%;

          &[disabled] {
            background-color: ${theme.colors['gray-dark']};
            color: black;
            cursor: not-allowed;
            font-size: 12px;
            font-weight: bold;
            padding: 0 5px;

            .caret {
              display: none;
            }
          }
        `}
        type="button"
        {...getToggleButtonProps({ disabled })}
      >
        <span
          css={css`
            line-height: 1.2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `}
          dangerouslySetInnerHTML={{
            __html: value?.label || 'Veuillez sélectionner',
          }}
        ></span>

        <svg
          className="caret"
          css={css`
            margin-left: auto;
          `}
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 10 10"
        >
          <path
            d="M6.7,8.1,2,3.4,3.4,2,6.7,5.3,10,2l1.4,1.4Z"
            transform="translate(-2)"
            fill="#5f7817"
          />
          <rect width="10" height="10" fill="none" />
        </svg>
      </button>
      <ul
        {...getMenuProps()}
        css={css`
          background-color: ${theme.colors.white};
          border: 1px solid ${theme.colors['gray-dark']};
          font-size: 16px;
          list-style: none;
          margin: 0;
          padding: 0;
          position: absolute;
          visibility: ${isOpen && items?.length ? 'visible' : 'hidden'};
          width: 100%;
          z-index: 99;

          @media ${theme.breakpoints.desktop} {
            font-size: 15px;
          }
        `}
      >
        {isOpen &&
          items.map((item, index) => (
            <li
              css={css`
                cursor: pointer;
                line-height: 1;
                padding: 5px 10px;

                &:hover {
                  background-color: ${theme.colors['gray']};
                }
              `}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
              dangerouslySetInnerHTML={{ __html: item.label }}
            ></li>
          ))}
      </ul>
      {errorMessage}
    </div>
  );
}
