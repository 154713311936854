/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export function Label({ children, htmlFor }) {
  return (
    <label
      css={css`
        display: block;
        font-size: 12px;
        line-height: 1.17;
        margin-bottom: 5px;
      `}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
}
