/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

export function ResultBox({ label, unit, value }) {
  const theme = useTheme();

  return (
    <div
      css={css`
        margin-bottom: 30px;

        @media ${theme.breakpoints.desktop} {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 23px;
          max-width: 470px;
        }
      `}
    >
      <span
        css={css`
          font-size: 12px;
          line-height: 1.75;
        `}
      >
        {label}
      </span>
      <span
        css={css`
          background-color: ${theme.colors.yellow};
          border: 1px solid ${theme.colors['yellow-dark']};
          display: block;
          font-size: 50px;
          font-weight: bold;
          line-height: 1;
          margin-top: 5px;
          padding: 12px;
          text-align: center;
        `}
      >
        {value} {unit}
      </span>
    </div>
  );
}
