/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Logo } from '../components/logo';
import { Nav } from '../components/nav';

const NAV_ITEMS = [
  {
    title: 'GUIDE DES RÉGLAGES THÉORIQUES',
    href: '/calculator/guide',
  },
  {
    title: 'CALCUL DOSE/HA DE MICROGRANULÉS',
    href: '/calculator/calcul',
  },
];

export function Home() {
  const theme = useTheme();

  return (
    <div
      css={css`
        @media ${theme.breakpoints.desktop} {
          display: flex;
        }
      `}
    >
      <div
        css={css`
          @media ${theme.breakpoints.desktop} {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-right: 80px;
            width: 33.33%;
          }
        `}
      >
        <div
          css={css`
            margin-bottom: 30px;
            text-align: center;

            @media ${theme.breakpoints.desktop} {
              text-align: left;
            }
          `}
        >
          <Logo />
        </div>
        <h1
          css={css`
            color: ${theme.colors.brand};
            font-size: 24px;
            line-height: 1.125;
            margin: 0 0 10px;

            @media ${theme.breakpoints.desktop} {
              font-size: 27px;
              line-height: 1.11;
              margin-bottom: 7px;
            }
          `}
        >
          SEMIS DE MAÏS 🌽
        </h1>
        <h2
          css={css`
            color: ${theme.colors.brand};
            font-size: 24px;
            line-height: 1.125;
            margin: 0 0 13px;

            @media ${theme.breakpoints.desktop} {
              font-size: 27px;
              line-height: 1.11;
              margin-bottom: 20px;
            }
          `}
        >
          BIENVENUE SUR VOTRE OUTIL D’AIDE POUR RÉGLER VOTRE MICROGRANULATEUR
        </h2>
        <p
          css={css`
            margin: 0 0 20px;
          `}
        >
          Cela vous guidera pour bien appliquer la dose de produit choisie.
        </p>
      </div>

      <Nav items={NAV_ITEMS} showLogo={false} />
    </div>
  );
}
