/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { Link } from 'react-router-dom';

export function Button({
  children,
  external,
  link,
  lowercase,
  onClick = () => {},
  type = 'button',
}) {
  const theme = useTheme();
  const style = css`
    appearance: none;
    background-color: ${theme.colors.brand};
    border: currentColor;
    border-radius: 12px;
    color: ${theme.colors.white};
    cursor: pointer;
    display: flex;
    font-size: 14px;
    line-height: 1.4285714286;
    padding: 9px;
    text-decoration: none;
    ${!lowercase ? 'text-transform: uppercase' : ''};

    &:hover {
      background-color: ${theme.colors['brand-light']};
    }
  `;

  const labelMarkup = (
    <span
      css={css`
        margin-left: 10px;
        margin-right: 20px;
      `}
    >
      {children}
    </span>
  );

  const iconMarkup = (
    <svg
      css={css`
        height: 21px;
        margin-left: auto;
        width: 21px;
      `}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 21"
    >
      <circle cx="10.5" cy="10.5" r="10.5" fill="#fff" />
      <path
        d="M15.006 10.169l-6.169 6.169L7 14.501l4.331-4.332L7 5.838l1.837-1.837z"
        fill="#5f7817"
      />
    </svg>
  );

  if (link) {
    return external ? (
      <a css={style} href={link}>
        {labelMarkup}
        {iconMarkup}
      </a>
    ) : (
      <Link css={style} to={link}>
        {labelMarkup}
        {iconMarkup}
      </Link>
    );
  }

  return (
    <button css={style} onClick={onClick} type={type}>
      {labelMarkup}
      {iconMarkup}
    </button>
  );
}
