/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

export function InlineError({ children }) {
  const theme = useTheme();

  return (
    <span
      css={css`
        color: ${theme.colors.danger};
        font-size: 12px;
        line-height: 1.17;
        position: absolute;
        margin-top: 5px;
        right: 0;
      `}
    >
      {children}
    </span>
  );
}
