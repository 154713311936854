/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

export function Layout({ children }) {
  const theme = useTheme();

  return (
    <div
      css={css`
        background-color: ${theme.colors.gray};
        font-family: Arial, sans-serif;
        font-size: 15px;
        line-height: 1.27;

        @media screen and (min-width: 1024px) {
          font-size: 18px;
          line-height: 1.28;
        }

        * {
          box-sizing: border-box;
        }

        img {
          height: auto;
          max-width: 100%;
          vertical-align: middle;
        }

        svg {
          vertical-align: middle;
        }
      `}
    >
      {children}
    </div>
  );
}
