/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

export function Wrapper({ children }) {
  const theme = useTheme();

  return (
    <div
      css={css`
        padding: 30px 36px 50px;

        @media ${theme.breakpoints.desktop} {
          margin-left: auto;
          margin-right: auto;
          max-width: 1212px;
          padding: 48px 36px 45px;
        }
      `}
    >
      {children}
    </div>
  );
}
