import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Nav } from '../components/nav';
import { Results } from './calculator/results';
import { Settings } from './calculator/settings';

export function Calculator() {
  const { path, url } = useRouteMatch();

  const navItems = [
    {
      title: 'GUIDE DES RÉGLAGES THÉORIQUES',
      href: `${url}/guide`,
    },
    {
      title: 'CALCUL DOSE/HA DE MICROGRANULÉS',
      href: `${url}/calcul`,
    },
  ];

  return (
    <>
      <Nav items={navItems} fullWidth />
      <Switch>
        <Route path={`${path}/guide`} exact>
          <Settings />
        </Route>
        <Route path={`${path}/calcul`} exact>
          <Results />
        </Route>
      </Switch>
    </>
  );
}
