/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box } from '../../components/box/box';
import { Button } from '../../components/button/button';
import { Select } from '../../components/select/select';
import data from '../../data/data.json';

const DEFAULT_VALUES = {
  brand: null,
  model: null,
  product: null,
  dose: null,
  spacing: null,
};

export function Settings() {
  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues: DEFAULT_VALUES,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [results, setResults] = useState([]);
  const theme = useTheme();

  const watchBrand = watch('brand');
  const watchProduct = watch('product');
  const watchModel = watch('model');
  const watchDose = watch('dose');
  const watchSpacing = watch('spacing');

  const selectedProduct = data.products.find(
    (product) => product.id === watchProduct?.value
  );

  const brandItems = data.brands.map((brand) => ({
    value: brand.id,
    label: brand.label,
  }));

  const modelItems = data.models
    .filter((model) => {
      if (!watchBrand) {
        return true;
      }

      return model.brand === watchBrand.value;
    })
    .map((model) => ({
      value: model.id,
      label: model.label,
    }));

  const productItems = data.products.map((product) => ({
    value: product.id,
    label: product.label,
  }));

  const doseItems = selectedProduct?.doses.map((dose) => ({
    value: dose,
    label: `${dose} kg/ha`,
  }));

  const spacingItems = selectedProduct?.spacing
    .filter((_, i) => {
      if (!watchModel) {
        return true;
      }

      const selectedModel = selectedProduct.models.find(
        (model) => model.id === watchModel.value
      );

      if (!watchDose) {
        return true;
      }

      const doseIndex = selectedProduct.doses.findIndex(
        (dose) => dose === watchDose.value
      );

      const results = selectedModel.results[0].values[doseIndex];

      return !!results[i];
    })
    .map((spacing) => {
      return {
        value: spacing,
        label: `${spacing} cm`,
      };
    });

  function handleFormSubmit(submittedData) {
    const selectedModel = selectedProduct.models.find(
      (model) => model.id === submittedData.model.value
    );
    const doseIndex = selectedProduct.doses.findIndex(
      (dose) => dose === submittedData.dose.value
    );
    const spacingIndex = selectedProduct.spacing.findIndex(
      (spacing) => spacing === submittedData.spacing.value
    );

    const results = selectedModel.results.map((result) => {
      const value = result.values[doseIndex][spacingIndex];

      return {
        label: result.label,
        value: value,
      };
    });

    setResults(results);
    setIsSubmitted(true);
  }

  function handleEditClick() {
    setIsSubmitted(false);
  }

  return (
    <div
      css={css`
        padding-top: 30px;
      `}
    >
      {!isSubmitted ? (
        <p
          css={css`
            margin: 0 0 15px;

            @media ${theme.breakpoints.desktop} {
              margin-bottom: 25px;
            }
          `}
        >
          Pour régler votre microgranulateur en fonction de la dose/ha
          préconisée et de la pression ravageurs, répondez à ces 5 questions :
        </p>
      ) : null}

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <p
          css={css`
            margin: 0 0 10px;

            @media ${theme.breakpoints.desktop} {
              font-size: 15px;
              line-height: 1.4;
            }
          `}
        >
          <strong>
            {isSubmitted
              ? 'Rappel de vos informations :'
              : 'Vos informations :'}
          </strong>
        </p>

        <div
          css={css`
            @media ${theme.breakpoints.desktop} {
              display: grid;
              grid-template-columns: repeat(2, minmax(1px, 1fr));
              gap: 0 55px;
            }
          `}
        >
          <div>
            <Controller
              control={control}
              name="brand"
              rules={{ required: true }}
              render={({ onChange, ref, ...rest }) => (
                <Select
                  disabled={isSubmitted}
                  items={brandItems}
                  label="Marque du semoir"
                  onChange={(selectedItem) => {
                    onChange(selectedItem);

                    if (selectedItem.value !== watchModel?.value) {
                      setValue('model', null);
                    }
                  }}
                  {...rest}
                />
              )}
            />

            <Controller
              control={control}
              name="model"
              rules={{ required: true }}
              render={({ ref, ...rest }) => (
                <Select
                  disabled={isSubmitted}
                  items={modelItems}
                  label="Modèle de microgranulateur"
                  {...rest}
                />
              )}
            />

            <Controller
              control={control}
              name="product"
              rules={{ required: true }}
              render={({ ref, ...rest }) => (
                <Select
                  disabled={isSubmitted}
                  items={productItems}
                  label="Nom du produit utilisé"
                  {...rest}
                />
              )}
            />
          </div>

          <div>
            <Controller
              control={control}
              name="dose"
              rules={{ required: true }}
              render={({ ref, onChange, ...rest }) => (
                <Select
                  disabled={isSubmitted}
                  items={doseItems}
                  label="Dose de microgranulés en kg/ha choisie"
                  onChange={(selectedItem) => {
                    onChange(selectedItem);

                    if (
                      !watchDose ||
                      !watchModel ||
                      !watchSpacing ||
                      !selectedProduct
                    ) {
                      return true;
                    }

                    const selectedModel = selectedProduct.models.find(
                      (model) => model.id === watchModel.value
                    );

                    const doseIndex = selectedProduct.doses.findIndex(
                      (dose) => dose === selectedItem.value
                    );

                    const spacingIndex = selectedProduct.spacing.findIndex(
                      (spacing) => spacing === watchSpacing.value
                    );

                    if (
                      !selectedModel.results[0].values[doseIndex][spacingIndex]
                    ) {
                      setValue('spacing', null);
                    }
                  }}
                  {...rest}
                />
              )}
            />

            <Controller
              control={control}
              name="spacing"
              rules={{ required: true }}
              render={({ ref, ...rest }) => (
                <Select
                  disabled={isSubmitted}
                  items={spacingItems}
                  label="Écartement de l’inter-rang"
                  {...rest}
                />
              )}
            />

            {isSubmitted && (
              <div
                css={css`
                  display: flex;
                  justify-content: flex-end;
                `}
              >
                <button
                  css={css`
                    appearance: none;
                    background-color: transparent;
                    border: none;
                    color: #5f7817;
                    cursor: pointer;
                    padding: 0;
                  `}
                  onClick={handleEditClick}
                  type="submit"
                >
                  Modifier vos informations
                </button>
              </div>
            )}
          </div>
        </div>

        {isSubmitted &&
          (results.length ? (
            <div
              css={css`
                margin-top: 30px;
              `}
            >
              <h2
                css={css`
                  font-size: 15px;
                  line-height: 1.4;
                  margin: 0;
                `}
              >
                VOS RÉGLAGES À FAIRE :
              </h2>
              <div
                css={css`
                  margin-top: 10px;

                  @media ${theme.breakpoints.desktop} {
                    display: grid;
                    grid-template-columns: repeat(3, minmax(1px, 1fr));
                  }

                  > * + * {
                    margin-top: 10px;

                    @media ${theme.breakpoints.desktop} {
                      margin-top: 0;
                    }
                  }

                  > :nth-child(2) {
                    @media ${theme.breakpoints.desktop} {
                      justify-self: center;
                    }
                  }

                  > :nth-child(3) {
                    @media ${theme.breakpoints.desktop} {
                      justify-self: flex-end;
                    }
                  }
                `}
              >
                {results.map((result) => (
                  <Box
                    key={result.label}
                    label={`${result.label}\u00A0:`}
                    value={result.value}
                  />
                ))}
              </div>
            </div>
          ) : null)}

        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;

            @media ${theme.breakpoints.desktop} {
              justify-content: center;
              margin-top: 23px;
            }
          `}
        >
          {isSubmitted ? (
            <Button link={`/calculator/calcul`}>Vérifier la dose/ha</Button>
          ) : (
            <Button type="submit">Voir les réglages</Button>
          )}
        </div>

        {isSubmitted ? (
          <p
            css={css`
              margin-bottom: 0;
              margin-top: 30px;
            `}
          >
            Les valeurs de débit indiquées ne sont qu’indicatives et peuvent
            varier en fonction de l’état du matériel utilisé. <br />
            Elles doivent être vérifiées régulièrement par vos soins.
          </p>
        ) : null}
      </form>
    </div>
  );
}
