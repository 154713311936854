/** @jsxImportSource @emotion/react */
import { css, Global, ThemeProvider } from '@emotion/react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Calculator } from '../../screens/calculator';
import { Home } from '../../screens/home';
import { Layout } from '../layout';
import { Wrapper } from '../wrapper';

const theme = {
  colors: {
    brand: '#5f7800',
    'brand-light': '#9EB06B',
    gray: '#F5F5F5',
    'gray-dark': '#DADBDF',
    white: '#fff',
    yellow: '#FFD300',
    'yellow-dark': '#DBB604',
    danger: '#F50303',
  },
  breakpoints: {
    desktop: 'screen and (min-width: 1024px)',
  },
};

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Global
          styles={css`
            body {
              margin: 0;
              padding: 0;
            }
          `}
        />
        <Wrapper>
          <Router>
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/calculator">
                <Calculator />
              </Route>
            </Switch>
          </Router>
        </Wrapper>
      </Layout>
    </ThemeProvider>
  );
}
