/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Button } from '../../components/button/button';
import { InlineError } from '../../components/inline-error/inline-error';
import { Label } from '../../components/label/label';
import { ResultBox } from '../../components/result-box';
import { Select } from '../../components/select';
import data from '../../data/data.json';

const DEFAULT_VALUES = {
  product: null,
  row_spacing: 75,
  wheel: 2,
  wheel_count: 50,
  rows_count: {
    label: 1,
    value: 1,
  },
};

export function Results() {
  const {
    control,
    errors,
    formState,
    handleSubmit,
    getValues,
    register,
    reset,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });
  const values = useWatch({ control });
  const theme = useTheme();
  const [result, setResult] = useState();

  const selectedProduct =
    values.product &&
    data.products.find((product) => product.id === values.product.value);

  const productItems = data.products.map((product) => ({
    value: product.id,
    label: product.label,
  }));

  function handleFormSubmit() {
    setResult(
      (values.mass_weight * 10) /
        (values.wheel_count *
          (values.row_spacing / 100) *
          values.rows_count.value *
          parseFloat(values.wheel.replace(',', '.')))
    );
  }

  function handleEditClick() {
    reset(getValues());
  }

  return (
    <div
      css={css`
        padding-top: 30px;
      `}
    >
      <form
        css={css`
          margin-bottom: 30px;
        `}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <div
          css={css`
            @media ${theme.breakpoints.desktop} {
              display: grid;
              grid-template-columns: repeat(2, minmax(1px, 1fr));
              gap: 0 55px;
            }
          `}
        >
          <div>
            <Controller
              control={control}
              name="product"
              rules={{ required: true }}
              render={({ ref, ...rest }, { invalid }) => (
                <Select
                  errorMessage={
                    errors.product && (
                      <InlineError>Merci de sélectionner svp.</InlineError>
                    )
                  }
                  invalid={invalid}
                  items={productItems}
                  label="Nom du produit utilisé"
                  {...rest}
                />
              )}
            />
            <div
              css={css`
                margin-bottom: 10px;
                position: relative;

                @media ${theme.breakpoints.desktop} {
                  margin-bottom: 17px;
                }
              `}
            >
              <Label htmlFor="wheel">
                Circonférence de la roue (en mètres)
              </Label>
              <input
                css={css`
                  align-items: center;
                  appearance: none;
                  background-color: ${theme.colors.white};
                  border: 1px solid
                    ${errors.wheel
                      ? theme.colors.danger
                      : theme.colors['gray-dark']};
                  border-radius: 0;
                  color: black;
                  display: flex;
                  font-size: 16px;
                  line-height: normal;
                  padding: 10px;
                  text-align: left;
                  width: 100%;

                  @media ${theme.breakpoints.desktop} {
                    font-size: 15px;
                  }
                `}
                id="wheel"
                defaultValue={DEFAULT_VALUES.wheel}
                name="wheel"
                ref={register({
                  required: true,
                  validate: {
                    min: (value) => parseFloat(value.replace(',', '.')) >= 1.0,
                    max: (value) => parseFloat(value.replace(',', '.')) <= 3.0,
                  },
                })}
                type="text"
              />
              {errors.wheel && (
                <InlineError>
                  Erreur de saisie, merci de vérifier svp.
                </InlineError>
              )}
            </div>
            <div
              css={css`
                margin-bottom: 10px;

                @media ${theme.breakpoints.desktop} {
                  margin-bottom: 17px;
                }
              `}
            >
              <Label htmlFor="wheel_count">Nombre de tours de roues</Label>
              <input
                css={css`
                  align-items: center;
                  appearance: none;
                  background-color: ${theme.colors.white};
                  border: 1px solid ${theme.colors['gray-dark']};
                  border-radius: 0;
                  display: flex;
                  font-size: 16px;
                  line-height: normal;
                  padding: 10px;
                  text-align: left;
                  width: 100%;

                  @media ${theme.breakpoints.desktop} {
                    font-size: 15px;
                  }

                  &[readonly] {
                    cursor: not-allowed;
                    background-color: ${theme.colors['gray-dark']};
                    border: 1px solid ${theme.colors['gray-dark']};
                  }
                `}
                id="wheel_count"
                defaultValue={DEFAULT_VALUES.wheel_count}
                name="wheel_count"
                ref={register({ required: true })}
                readOnly
                type="number"
              />
            </div>
          </div>
          <div>
            <div
              css={css`
                margin-bottom: 10px;
                position: relative;

                @media ${theme.breakpoints.desktop} {
                  margin-bottom: 17px;
                }
              `}
            >
              <Label htmlFor="row_spacing">
                Écartement de l’inter-rang (en centimètres)
              </Label>
              <input
                css={css`
                  align-items: center;
                  appearance: none;
                  background-color: ${theme.colors.white};
                  border: 1px solid
                    ${errors.row_spacing
                      ? theme.colors.danger
                      : theme.colors['gray-dark']};
                  border-radius: 0;
                  color: black;
                  display: flex;
                  font-size: 16px;
                  line-height: normal;
                  padding: 10px;
                  text-align: left;
                  width: 100%;

                  @media ${theme.breakpoints.desktop} {
                    font-size: 15px;
                  }
                `}
                defaultValue={DEFAULT_VALUES.row_spacing}
                id="row_spacing"
                name="row_spacing"
                ref={register({
                  required: true,
                  pattern: {
                    value: /^\d*$/i,
                    message: 'Invalid',
                  },
                  validate: {
                    min: (value) => parseInt(value) >= 50,
                    max: (value) => parseInt(value) <= 90,
                  },
                })}
                type="text"
              />
              {errors.row_spacing && (
                <InlineError>
                  Erreur de saisie, merci de vérifier svp.
                </InlineError>
              )}
            </div>
            <Controller
              control={control}
              name="rows_count"
              rules={{ required: true }}
              render={({ ref, ...rest }) => (
                <Select
                  defaultSelectedItem={DEFAULT_VALUES.rows_count}
                  items={[
                    { value: 1, label: '1' },
                    { value: 2, label: '2' },
                  ]}
                  label="Nombre de rangs dont les microgranulés sont pesés"
                  {...rest}
                />
              )}
            />
            <div
              css={css`
                margin-bottom: 10px;
                position: relative;

                @media ${theme.breakpoints.desktop} {
                  margin-bottom: 17px;
                }
              `}
            >
              <Label htmlFor="mass_weight">Masse pesée (en grammes)</Label>
              <input
                css={css`
                  align-items: center;
                  appearance: none;
                  background-color: ${theme.colors.white};
                  border: 1px solid
                    ${errors.mass_weight
                      ? theme.colors.danger
                      : theme.colors['gray-dark']};
                  border-radius: 0;
                  color: black;
                  display: flex;
                  font-size: 16px;
                  line-height: normal;
                  padding: 10px;
                  text-align: left;
                  width: 100%;

                  @media ${theme.breakpoints.desktop} {
                    font-size: 15px;
                  }
                `}
                defaultValue={DEFAULT_VALUES.mass_weight}
                id="mass_weight"
                name="mass_weight"
                placeholder="Votre masse"
                ref={register({
                  required: true,
                  pattern: {
                    value: /^\d*$/i,
                    message: 'Invalid',
                  },
                  validate: {
                    min: (value) => parseInt(value) >= 15,
                    max: (value) => parseInt(value) <= 600,
                  },
                })}
                type="text"
              />
              {errors.mass_weight && (
                <InlineError>
                  Erreur de saisie, merci de vérifier svp.
                </InlineError>
              )}
            </div>
            {formState.isSubmitSuccessful && (
              <div
                css={css`
                  display: flex;
                  justify-content: flex-end;
                `}
              >
                <button
                  css={css`
                    appearance: none;
                    background-color: transparent;
                    border: none;
                    color: #5f7817;
                    cursor: pointer;
                    padding: 0;
                  `}
                  onClick={handleEditClick}
                  type="submit"
                >
                  Modifier vos informations
                </button>
              </div>
            )}
          </div>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;

            @media ${theme.breakpoints.desktop} {
              justify-content: center;
            }
          `}
        >
          {!formState.isSubmitSuccessful ? (
            <Button type="submit">CALCULER</Button>
          ) : null}
        </div>
      </form>

      {formState.isSubmitSuccessful ? (
        <>
          {result ? (
            <ResultBox
              label="Résultat (Dose / ha)"
              unit="kg/ha"
              value={result.toFixed(2)}
            />
          ) : null}
          <div
            css={css`
              display: flex;
              justify-content: flex-end;

              @media ${theme.breakpoints.desktop} {
                justify-content: center;
              }
            `}
          >
            <Button link={`/calculator/guide`}>VÉRIFIER LES RÉGLAGES</Button>
          </div>
          {selectedProduct ? (
            <>
              <p
                css={css`
                  margin-bottom: 25px;
                  margin-top: 50px;

                  @media ${theme.breakpoints.desktop} {
                    margin-bottom: 22px;
                    margin-top: 43px;
                  }
                `}
              >
                {selectedProduct.description}
                <br />
                Pour plus d’information sur le produit et ses bonnes conditions
                d’utilisation, reportez-vous à la fiche technique.
              </p>
              <div
                css={css`
                  display: flex;
                  justify-content: flex-end;

                  @media ${theme.breakpoints.desktop} {
                    justify-content: center;
                  }
                `}
              >
                <Button link={selectedProduct.url} lowercase external>
                  Consulter la fiche technique
                </Button>
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </div>
  );
}
