/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';

export function Box({ label, value }) {
  const theme = useTheme();

  return (
    <div
      css={css`
        align-items: center;
        border: 1px solid ${theme.colors['yellow-dark']};
        background-color: ${theme.colors.yellow};
        display: flex;
        line-height: 1.4;
        padding: 10px;

        @media ${theme.breakpoints.desktop} {
          flex-direction: column;
          max-width: 212px;
          padding: 42px 20px 18px;
          position: relative;
          width: 100%;
        }
      `}
    >
      <svg
        css={css`
          margin-right: 11px;

          @media ${theme.breakpoints.desktop} {
            height: 28px;
            left: 6px;
            margin-right: 0;
            position: absolute;
            top: 6px;
            width: 28px;
          }
        `}
        xmlns="http://www.w3.org/2000/svg"
        width="13.7"
        height="13.7"
        viewBox="0 0 13.7 13.7"
      >
        <path
          d="M7.071.65a.85.85,0,0,0-.829.662L6,2.362a5.323,5.323,0,0,0-1.08.448l-.911-.574a.85.85,0,0,0-1.054.118l-.606.606a.85.85,0,0,0-.118,1.054l.574.911A5.322,5.322,0,0,0,2.362,6l-1.05.238a.85.85,0,0,0-.662.829v.857a.85.85,0,0,0,.662.829L2.362,9a5.323,5.323,0,0,0,.448,1.079l-.574.911a.85.85,0,0,0,.118,1.054l.606.606a.85.85,0,0,0,1.054.118l.911-.574A5.321,5.321,0,0,0,6,12.638l.238,1.05a.85.85,0,0,0,.829.662h.857a.85.85,0,0,0,.829-.662L9,12.638a5.323,5.323,0,0,0,1.08-.447l.911.574a.85.85,0,0,0,1.054-.118l.606-.606a.85.85,0,0,0,.118-1.054l-.574-.911A5.319,5.319,0,0,0,12.638,9l1.05-.238a.85.85,0,0,0,.662-.829V7.072a.85.85,0,0,0-.662-.829L12.638,6a5.317,5.317,0,0,0-.447-1.08l.574-.911a.85.85,0,0,0-.118-1.054l-.606-.606a.85.85,0,0,0-1.054-.118l-.911.574A5.323,5.323,0,0,0,9,2.362l-.238-1.05A.85.85,0,0,0,7.928.65ZM4.921,3.813a4.476,4.476,0,0,1,1.795-.744L7.071,1.5h.857l.355,1.568a4.476,4.476,0,0,1,1.8.744l1.36-.858.606.606-.858,1.36a4.475,4.475,0,0,1,.744,1.8l1.568.355v.857l-1.568.355a4.475,4.475,0,0,1-.744,1.8l.857,1.36-.606.606-1.361-.858a4.476,4.476,0,0,1-1.8.744L7.928,13.5H7.071l-.356-1.568a4.475,4.475,0,0,1-1.794-.744l-1.361.858-.606-.606.858-1.361a4.476,4.476,0,0,1-.744-1.795L1.5,7.929V7.072l1.568-.356a4.476,4.476,0,0,1,.744-1.795L2.954,3.561l.606-.606ZM9.025,7.5A1.525,1.525,0,1,1,7.5,5.975,1.525,1.525,0,0,1,9.025,7.5Zm.9,0A2.425,2.425,0,1,1,7.5,5.075,2.425,2.425,0,0,1,9.925,7.5Z"
          transform="translate(-0.65 -0.65)"
          fillRule="evenodd"
        />
      </svg>

      <span
        css={css`
          @media ${theme.breakpoints.desktop} {
            font-size: 15px;
            line-height: 1.2;
          }
        `}
      >
        {label}
      </span>
      <span
        css={css`
          @media ${theme.breakpoints.desktop} {
            display: none;
          }
        `}
      >
        {'\u00A0'}
      </span>
      <span
        css={css`
          font-weight: bold;

          @media ${theme.breakpoints.desktop} {
            font-size: 34px;
            line-height: 1;
          }
        `}
      >
        {value}
      </span>
    </div>
  );
}
