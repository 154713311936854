/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react';
import { NavLink } from 'react-router-dom';
import bgImage from '../../images/Photo_outil.jpg';
import bgImageRetina from '../../images/Photo_outil@2x.jpg';
import bgImageSmall from '../../images/Photo_outil-small.jpg';
import bgImageSmallRetina from '../../images/Photo_outil-small@2x.jpg';
import { Logo } from '../logo';

export function Nav({ fullWidth, items, showLogo = true }) {
  const theme = useTheme();

  return (
    <div
      css={css`
        left: 50%;
        margin-left: -50vw;
        position: relative;
        width: 100vw;

        @media ${theme.breakpoints.desktop} {
          margin-left: auto;
          position: static;
          width: ${fullWidth ? '100%' : '66.67%'};
        }
      `}
    >
      {showLogo ? (
        <div
          css={css`
            margin-bottom: 30px;
            padding-left: 36px;
            padding-right: 36px;
            text-align: center;

            @media ${theme.breakpoints.desktop} {
              margin-bottom: -100px;
              padding-left: 0;
              padding-right: 0;
              text-align: left;
            }
          `}
        >
          <Logo />
        </div>
      ) : null}
      <div
        css={css`
          display: ${fullWidth ? 'none' : 'block'};

          @media ${theme.breakpoints.desktop} {
            display: block;
            margin-left: auto;
            max-width: ${fullWidth ? '662px' : '100%'};
          }
        `}
      >
        {fullWidth ? (
          <img
            css={css`
              max-width: none;
              width: 100%;
            `}
            src={bgImageSmall}
            srcSet={`${bgImageSmall} 1x, ${bgImageSmallRetina} 2x`}
            alt=""
            width="662"
            height="141"
          />
        ) : (
          <img
            css={css`
              max-width: none;
              width: 100%;
            `}
            src={bgImage}
            srcSet={`${bgImage} 1x, ${bgImageRetina} 2x`}
            alt=""
            width="760"
            height="355"
          />
        )}
      </div>
      <div
        css={css`
          margin-top: ${fullWidth ? '0' : '-20px'};
          padding-left: 36px;
          padding-right: 36px;

          @media ${theme.breakpoints.desktop} {
            margin-top: ${fullWidth ? '-40px' : '-64px'};
          }
        `}
      >
        <nav
          css={css`
            display: grid;
            margin-left: auto;
            margin-right: auto;
            max-width: 566px;
            grid-template-columns: repeat(2, minmax(1px, 1fr));
            gap: 0 10px;
          `}
        >
          {items.map((item) => (
            <NavLink
              css={css`
                background-color: ${theme.colors.brand};
                border: 1px solid ${theme.colors.brand};
                border-radius: 12px;
                color: ${theme.colors.white};
                display: flex;
                font-size: 14px;
                line-height: 1.4285714286;
                min-height: 117px;
                padding: 9px;
                position: relative;
                text-decoration: none;

                @media ${theme.breakpoints.desktop} {
                  font-size: 18px;
                  line-height: 1.333;
                }

                &:hover {
                  background-color: ${theme.colors['brand-light']};
                }

                &.is-active {
                  background-color: ${theme.colors['brand-light']};
                  border-color: ${theme.colors['brand-light']};
                }
              `}
              activeClassName="is-active"
              key={item.href}
              to={item.href}
            >
              {item.title}

              <svg
                css={css`
                  align-self: flex-end;
                  bottom: 9px;
                  flex: none;
                  height: 21px;
                  position: absolute;
                  right: 9px;
                  width: 21px;
                `}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 21 21"
              >
                <circle cx="10.5" cy="10.5" r="10.5" fill="#fff" />
                <path
                  d="M15.006 10.169l-6.169 6.169L7 14.501l4.331-4.332L7 5.838l1.837-1.837z"
                  fill="#5f7817"
                />
              </svg>
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
}
